<template>
  <div>
    <div v-if="type == 101">
      <div class="compont_p">
        意见反馈
        <div class="btn flex_c_c" @click="type = 102">添加</div>
      </div>
      <div>
        <el-form :inline="true" :model="form" class="mt_20">
          <el-form-item label="反馈内容：">
            <el-input v-model="form.notes" clearable placeholder="反馈内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="custombtn" type="primary" @click="getOpinion">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table :data="tableData">
          <el-table-column prop="complaintsType_str" label="反馈类型" align="center" />
          <el-table-column prop="notes" label="反馈内容" align="center" />
          <el-table-column prop="ddbh" label="相关订单" align="center" />
          <el-table-column prop="time_str" label="反馈时间" align="center" />
          <el-table-column prop="reply_str" label="回复状态" align="center" />
          <el-table-column prop="reply" label="回复内容" align="center" />
          <!-- <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <div class="check flex_c_c" @click="handleClick(scope.row)">
                查看
              </div>
            </template>
          </el-table-column> -->
        </el-table>
        <pagination />
      </div>
    </div>

    <div v-if="type == 102">
      <div class="compont_p">
        添加反馈
        <div class="btn flex_c_c btn1" @click="back">返回上级</div>
      </div>
      <div class="form_wrap">
        <el-form :model="formInline" label-width="110px">
          <el-form-item label="反馈类型：">
            <el-radio-group v-model="formInline.complaintsType">
              <el-radio v-for="item in options" :key="item.id" :label="item.id">
                {{ item.title }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="相关订单编号：">
            <el-input style="width: 200px" v-model="formInline.ddbh" placeholder="相关订单编号"></el-input>
          </el-form-item>
          <el-form-item label="反馈内容：">
            <el-input type="textarea" style="width: 520px" :rows="5" v-model="formInline.notes"></el-input>
          </el-form-item>
        </el-form>
        <div class="sub_btn flex_c_c" @click="onSubmit">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue";

export default {
  components: {
    pagination,
  },
  data() {
    return {
      type: 101,
      tableData: [],
      form: { notes: "" },
      formInline: {
        complaintsType: 1,
        ddbh: "",
        notes: "",
      },
      value1: "",
      options: [],
    };
  },
  created() {
    this.getOpinion();
    this.$api("account.getOpinionType").then((res) => {
      this.options = res.data;
    });
  },
  methods: {
    getOpinion() {
      this.$api("account.getOpinion", this.form).then((res) => {
        this.tableData = res.data.list;
      });
    },
    back() {
      this.type = 101;
      this.getOpinion();
    },
    handleClick() {},
    onSubmit() {
      this.$api("account.updateOpinion", this.formInline).then(() => {
        this.$message.success("提交成功");
        this.back();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.compont_p {
  position: relative;
  .btn {
    position: absolute;
    left: 110px;
    top: 0;
    width: 76px;
    height: 30px;
    line-height: 30px;
    background: #ff4c4c;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }
  .btn1 {
    background: @themeColor;
  }
}
.check {
  width: 48px;
  height: 30px;
  background: @themeColor;
  border-radius: 4px;
  color: #ffffff;
}
.form_wrap {
  margin-top: 20px;
}
.sub_btn {
  margin-left: 100px;
  width: 104px;
  height: 30px;
  background: #ff4c4c;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
</style>
